import './../styles/global.scss';
import * as React from 'react';
import { HeadFC, PageProps } from 'gatsby';
import { GoAlert } from '@react-icons/all-files/go/GoAlert';
import { StaticImage } from 'gatsby-plugin-image';

const NotFoundPage: React.FC<PageProps> = () => {
  return (
    <main className="h-screen w-screen flex items-center justify-center relative overflow-hidden">
      <img
        src="https://firebasestorage.googleapis.com/v0/b/vendoor-19bc0.appspot.com/o/images%2F404.webp?alt=media&token=0fbabe99-4c76-4bf3-bfa1-19709ee9cbb7"
        className="h-full w-full object-cover scale-[2] origin-top "
      />

      <div className="absolute z-10 text-white text-[58vh] font-bold w-full h-full flex items-center justify-center font=['Inter']">
        404
      </div>

      <div className="absolute w-full h-full z-20 flex items-center justify-center">
        <div className="bg-[#E1F317] z-30 top-0 opacity-80 absolute  w-full h-full" />

        <div className="bg-white rounded-lg py-11 px-8 opacity-100 z-30 relative flex flex-col justify-center items-center mx-2 w-full max-w-[501px] mt-16">
          <StaticImage
            src={'../images/logo_vendoor.png'}
            alt={'vendoor logo'}
            className="w-[247px] h-[73px] absolute top-[-170px]"
          />
          <div className="absolute top-[-59px] bg-white pt-2.5 pb-8 px-5 rounded-full left-0 right-0 mx-auto w-28">
            <GoAlert color="#E53E3E" size="72px" />
          </div>

          <div className="text-[#131313] text-center font-[600] text-2xl relative z-50">
            Página não encontrada
          </div>

          <div className="text-[#718096] text-center font-[400] text-[16px] mt-3 mb-7 max-w-[346px]">
            Ops, Não encontramos essa página. Verifique o endereço colocado.
          </div>

          <a
            className="bg-black h-10 text-[#FFFFFF] flex items-center justify-center px-4 rounded font-[600] text-[12px] w-[283px]"
            href="/"
          >
            Ver vagas
          </a>
        </div>
      </div>
    </main>
  );
};

export default NotFoundPage;

export const Head: HeadFC = () => <title>Página não encontrada</title>;
